import React from "react"
import "../styles/social.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import { Link } from 'gatsby'
import { containerFast, container, fadeDown, scaleUp, socialScaleUp, fadeUp } from "../animations"

const IndexPage = () => (
  <Layout className="social-container">
    <SEO title="Social Media" description="Connect with us on our various social media platforms"/>
    <motion.div
      variants={container}
      initial="out"
      animate="in"
      className="text-container"
    >
      <motion.h1 variants={fadeDown}>Social Media</motion.h1>
    </motion.div>
    <motion.div
      variants={containerFast}
      initial="out"
      animate="in"
      className="social-grid"
    >
      <motion.a
        variants={socialScaleUp}
        whileHover={scaleUp}
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/BirchandBenjamin/"
      >
        <img
          alt="Facebook"
          src="https://img.icons8.com/doodle/384/000000/facebook-new.png"
        />
        <p>Facebook</p>
      </motion.a>
      <motion.a
        variants={socialScaleUp}
        whileHover={scaleUp}
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/birchandbenjamin/"
      >
        <img
          alt="Instagram"
          src="https://img.icons8.com/doodle/384/000000/instagram-new.png"
        />
        <p>Instagram</p>
      </motion.a>
      <motion.a
        variants={socialScaleUp}
        whileHover={scaleUp}
        target="_blank"
        rel="noreferrer"
        href="https://pinterest.com"
      >
        <img
          alt="Pinterest"
          src="https://img.icons8.com/doodle/384/000000/pinterest.png"
        />
        <p>Pinterest</p>
      </motion.a>
      <motion.a
        variants={socialScaleUp}
        whileHover={scaleUp}
        target="_blank"
        rel="noreferrer"
        href="https://g.page/BirchandBenjamin?share"
      >
        <img
          alt="Maps"
          src="https://img.icons8.com/doodle/384/000000/google-maps-new.png"
        />
        <p>Google Maps</p>
      </motion.a>
      <motion.a
        variants={socialScaleUp}
        whileHover={scaleUp}
        target="_blank"
        rel="noreferrer"
        href="mailto:info@birchandbenjamin.com"
      >
        <img
          alt="Email"
          src="https://img.icons8.com/doodle/384/000000/mail-with-wings.png"
        />
        <p>Email</p>
      </motion.a>
    </motion.div>
    <motion.nav
      variants={container}
      initial="out"
      animate="in"
      className="nav-row"
    >
      <motion.div variants={fadeUp} whileHover={scaleUp}>
        <Link to={"/"}>Go Back</Link>
      </motion.div>
    </motion.nav>
  </Layout>
)

export default IndexPage
